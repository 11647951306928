<template>
  <simpleNotifyPage :action="action" :hasBar="false">
    <template v-slot:subtitle>
      你的商家尚未與{{ layoutProviderName }}合作，需請你商家的主要管理員申請
    </template>
  </simpleNotifyPage>
</template>

<script>
export default {
  components: {
    simpleNotifyPage: () =>
      import("@/modules/base/views/notifyPage/simpleNotifyPage.vue"),
  },
  computed: {
    layoutProviderName() {
      return this.$store.getters[`base/layoutProviderName`];
    },
  },
  methods: {
    action() {
      this.$helper.closeLiff();
    },
  },
};
</script>